.c-person__hero {
	position: relative;
}

.c-person__hero-image-wrapper {
	height: 200px;
	overflow: hidden;

	@include bp("small") {
		height: 300px;
	}

	@include bp("large") {
		height: 450px;
	}

	img {
		height: 200px;
		object-position: top;

		@include bp("small") {
			height: 300px;
		}

		@include bp("medium") {
			height: 450px;
		}
	}
}

.c-person__hero-image {
	display: none;

	@include bp("medium") {
		display: block;
	}
}

.c-person__hero-image-mobile {
	display: block;

	@include bp("medium") {
		display: none;
	}
}

.c-person__hero-bio-image-wrapper {
	display: none;
}

.c-person__hero-details {
	position: relative;
	padding: 20px 0 40px;

	@include bp("medium") {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 25px;
	}
}

.c-person__hero-details-container {
	@include layout-wrapper;
	height: 100%;

	@include bp("medium") {
		display: grid;
		grid-template-rows: 1fr 200px 1fr;
	}
}

.c-person__hero-details-wrapper {
	grid-row-start: 2;
	color: $grey--darkest;

	&--light {
		color: $white;

		.c-person__hero-iwi {
			color: $white;
		}

		.c-person__hero-role-container .c-person__hero-separator {
			background: $white;
		}
	}
}
/* Details Block */

.c-person__hero-name-container {
	display: flex;
	align-items: baseline;
}

.c-person__hero-name {
	@include text-black(26px, 28px);
	margin-right: 10px;

	@include bp("medium") {
		@include text-black(38px, 40px);
	}
}

.c-person__hero-iwi {
	@include text-heavy(14px, 16px);
	color: $tropical-rain-forest;

	@include bp("medium") {
		@include text-heavy(16px, 24px);
	}
}

.c-person__hero-role-container {
	@include text-heavy(16px, 14px);
	display: flex;
	align-items: center;
	margin-top: 10px;

	@include bp("medium") {
		@include text-heavy(18px, 24px);
	}

	.c-person__hero-separator {
		width: 2px;
		height: 20px;
		background: $grey--darkest;
		margin: 0 10px;
	}
}

.c-person__hero-position {
	@include text-book(16px, 18px);

	@include bp("medium") {
		@include text-book(18px, 28px);
	}
}

.c-person__hero-button-container {
	display: flex;
	margin-top: 20px;

	a {
		margin-right: 10px
	}
}

.c-person__hero-phone,
.c-person__hero-mobile {
	@include button("small");
	@include btn-outline;
	background: #fff;

	@include bp("medium") {
		@include button("medium");
		@include btn-outline;
		background: #fff;
	}

	span[class^=iconf] {
		margin-right: 5px;
	}

	&.desktop {
		display: none;

		@include bp("medium") {
			display: inline-flex;
		}
	}

	&.mobile {
		display: inline-flex;

		@include bp("medium") {
			display: none;
		}
	}
}

.c-person__hero-email,
.c-person__hero-vcard,
.c-person__hero-linkedin {
	@include button("small", true);
	@include btn-outline;
	background: #fff;

	@include bp("medium") {
		@include button("medium", true);
		@include btn-outline;
		background: #fff;
	}
}

//.c-person__hero-phone-value,
//.c-person__hero-mobile-value,
.c-person__hero-email-value,
.c-person__hero-linkedin-value {
	display: none;
}

/* Tabbed Component */
.c-people-detail__tabs {
	background: $midnight-green;
	overflow: hidden;
	padding-bottom: 20px;
	position: relative;

	@include bp("medium") {
		padding-bottom: 0px;
	}
}

.c-people-detail__tabs-arrow {
	display: none;
	width: 25px;
	height: 25px;
	position: absolute;
	bottom: 10px;

	span {
		cursor: pointer;
		display: block;
		height: 25px;
		width: 25px;
		font-size: 25px;
		color: $aquamarine;
	}

	.c-people-detail__tabs.Scrolling & {
		display: block;
	}

	&.left {
		left: 15px;
	}

	&.right {
		right: 15px;
	}
}

.c-people-detail__tabs-wrapper {
	display: flex;
	overflow: auto;

	@include bp("medium") {
		@include layout-wrapper;
	}
}

.c-people-detail__tabs-item {
	@include text-heavy(18px, 24px);
	padding: 30px;
	display: block;
	margin-right: 0px;
	color: $white;
	position: relative;
	transition: all 0.3s;
	text-wrap: nowrap;

	@include bp("medium") {
		margin-right: 30px;
	}

	&[aria-current=true] {
		background: none;
		color: $teal;

		@include bp("medium") {
			background: $white;
			color: $tropical-rain-forest;
		}

		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			height: 3px;
			background: linear-gradient(270deg, #55EFC4 18.6%, #00B2A5 76.86%);
		}
	}

	&:hover {
		color: $teal;
	}
}

.c-people-tab__content {
	display: none;

	&[aria-current=true] {
		display: block;
	}
}
/* Tabbed Content */

.c-people-tab__heading {
	@include layout-wrapper;
	@include text-black(28px, 30px);
	margin-bottom: 20px;
	/* To compensate for the anchor tag injected above first control */
	&:first-child {
		+ * {
			margin-top: 0px;
		}
	}
}
/* Sidebar */

.c-people-tab__sidebar-header {
	@include text-heavy(12px, 16px);
	text-transform: uppercase;
	border-bottom: 2px solid $teal;
	margin-bottom: 20px;
	padding-bottom: 20px;
}

.c-people-tab__sidebar-subsection {
	border-bottom: 1px solid $grey--light;
	margin-bottom: 20px;
	padding-bottom: 20px;

	p:last-child {
		margin-bottom: 0px;
	}

	a {
		@include custom-text-link;
	}
}

.c-people-tab__sidebar-subsection-heading {
	@include text-heavy(18px, 24px);
	color: $tropical-rain-forest;
	margin-bottom: 10px;
}

/* Scroller */

.c-people-detail__tabs-wrapper::-webkit-scrollbar {
	width: 4px;
	height: 4px;
}

.c-people-detail__tabs-wrapper::-webkit-scrollbar-thumb {
	background-color: $aquamarine;
	border: 0px;
}

.c-people-detail__tabs-wrapper::-webkit-scrollbar-track {
	background: $rich-black;
	margin: 0 50px;
}
